import React, { useState } from 'react';

const locations = [
  {
    name:"Melbourne",
    top:84,
    left:76,
    flex:"column"
  },
  {
    name:"Sydney",
    top:71,
    left:93,
    flex:"column"
  },
  {
    name:"Morwell",
    top:79,
    left:80,
    flex:"column-reverse"
  },
  {
    name:"Albury",
    top:70,
    left:80,
    flex:"column-reverse"
  },
]
function NetMap() {

return (

<div className="mt-32 grid grid-flow-row-dense gap-8 lg:grid-cols-2 lg:grid-rows-1 sm:grid-cols-1 sm:grid-rows-2">

  <div className="flex justify-center items-center">
    <div>
      <div className="mb-6"><span className="text-4xl font-bold">SwiftFiber Network</span></div>
      <div className="mb-8"><span className="text-lg font-medium">
      Our network is backed by Corero DDoS protection and cutting-edge technology,<br/> ensuring your data is always protected and available whenever you need it.
      </span></div>
      <div className="p-4 bg-blue-400/20 border-2 border-blue-400 rounded-md mb-4">
        <div><span className="font-bold text-lg">Premium Transit</span></div>
        <div><span className="font-medium text-md">SwiftFiber utilises Global Secure Layer for our upstream connection allowing superior performance and low latency</span></div>
      </div>
      <div className="p-4 bg-red-400/20 border-2 border-red-400 rounded-md">
        <div><span className="font-bold text-lg">Corero DDoS protection</span></div>
        <div><span className="font-medium text-md">SwiftFiber utilises in-line DDoS mitigation devices from Corero ensuring your services never go down</span></div>
      </div>
    </div>
  </div>
  <div>
  <div className="relative overflow-auto inline-block">
  <img src="https://swiftportal.s3.ap-southeast-2.amazonaws.com/aus-outline.svg" />
  {locations.map((location) => (
  <div className="absolute translate-x-[-50%] translate-y-[-50%] flex justify-center items-center gap-1" style={{top:`${location.top}%`, left:`${location.left}%`, "flex-direction":`${location.flex}`}}>
<span class="relative flex h-3 w-3">
  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
  <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
</span>
    <div className='bg-slate-800 rounded-md px-2'>
      <span className="text-white text-sm">{location.name}</span>
    </div>
  </div>
  ))}


</div>
  </div>
</div>



);
}

export default NetMap;