import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, MinusIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import ContactForm from '../components/contactForm'
import Footer from '../components/footer'
import Navbar from '../components/nav'
import Partners from '../components/partners'
import Contact from '../components/contact'
import Network from '../components/network'
import logo from '../logo.svg';
import '../App.css';

const tiers = [
  {
    name: 'Small',
    id: 'small',
    href: 'javascript:void(Tawk_API.toggle())',
    priceMonthly: '$39',
    description: 'Small Plan',
    mostPopular: false,
  },
  {
    name: 'Medium',
    id: 'medium',
    href: 'javascript:void(Tawk_API.toggle())',
    priceMonthly: '$69',
    description: 'Medium Plan',
    mostPopular: true,
  },
  {
    name: 'Large',
    id: 'large',
    href: 'javascript:void(Tawk_API.toggle())',
    priceMonthly: '$99',
    description: 'Large Plan',
    mostPopular: false,
  },

]
const sections = [
  {
    name: 'Included',
    features: [
      { name: 'month-to-month plan', tiers: { small: '$59', medium: '$89', large: '$119' } },
      { name: 'Cloud PBX / SoftPhone System', tiers: { small: false, medium: true, large: true } },
      { name: 'Numbers', tiers: { small: '1', medium: '10', large: '20' } },
      { name: 'Channels', tiers: { small: '2', medium: '5', large: '10' } },

      { name: 'Additional Single Numbers', tiers: { small: '$1', medium: '$1', large: '$1' } },
      { name: '10 Number In dial', tiers: { small: '$5', medium: '$5', large: '$5' } },
      { name: '100 Number In dial', tiers: { small: '$30', medium: '$30', large: '$30' } },


      { name: 'Australia Fixed Line National (02, 03, 07, 08)', tiers: { small: '3 cents - Per Minute', medium: '2 cents - Per Minute', large: 'Included' } },
      { name: 'Australia Mobile (04xx)', tiers: { small: '8 cents - Per Minute', medium: '6 cents - Per Minute', large: 'Included' } },
      { name: 'Australia 13/1300/1345 Numbers', tiers: { small: '28 cents - Flat', medium: '25 cents - Flat', large: '10 cents - Flat' } },
      { name: 'Australia 180/1800 Numbers', tiers: { small: 'Included', medium: 'Included', large: 'Included' } },
      { name: 'Australia Directory Assistance (1223)', tiers: { small: '70 cents - Flat', medium: '65 cents - Flat', large: '50 cents - Flat' } },
      { name: 'Australia Special Numbers (1100, 125xxx)', tiers: { small: '28 cents - Flat', medium: '25 cents - Flat', large: '10 cents - Flat' } },
      { name: 'Australia Telstra/Optus Mobile Satellite (0147)', tiers: { small: '$1.60 - Per Minute with 85 cent Flagfall', medium: '$1.50 - Per Minute with 85 cent Flagfall', large: '$1.40 - Per Minute with 85 cent Flagfall' } },
    ],
  }
]




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function Albury() {

  const [contactmodalopen, setcontactmodalopen] = useState(false)

  const contactmodalcancelButtonRef = useRef(null)



  return (
<>

<Transition.Root show={contactmodalopen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={contactmodalcancelButtonRef} onClose={setcontactmodalopen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h3" className="text-center text-lg font-medium leading-6 text-gray-900">
                      Contact Form
                    </Dialog.Title>
                    <div className="mt-2">
                      <ContactForm/>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setcontactmodalopen(false)}
                    ref={contactmodalcancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    <Navbar/>
<div class="container mx-auto">


      {/* <div className='bgoverlay'></div> */}




  <div className="mt-20">
    <div className="bgoverlay-albury rounded-3xl lg:grid lg:grid-cols-2 lg:gap-4 px-16 py-16">
      <div className="flex justify-center items-center">
        <div>
          <div><span className="text-6xl font-bold text-slate-200">Albury</span></div>
          <div className="mt-6"><span className="text-xl font-medium text-slate-300">The internet hub of Victoria and New South Wales</span></div>
          <div>
            <a href="https://cloud.swiftfiber.com.au">
            <button
              type="button"
              className="mt-16 inline-flex items-center rounded-3xl border border-transparent bg-indigo-600 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Get Started
            </button>
            </a>
            <button
              type="button"
              onClick={() => setcontactmodalopen(true)}
              className="mt-16 ml-8 inline-flex items-center rounded-3xl border border-slate-300 px-8 py-2 text-base font-medium text-slate-300 hover:text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Get in contact
            </button>
          </div>
        </div>
      </div>
      <div className="justify-center items-center hidden lg:flex">
        <div>
          <img class="w-[600px] rounded-2xl" src="https://leadingedgedc.com/wp-content/uploads/2022/06/Slider-Image-1.jpg"/>
        </div>
      </div>
    </div>
<Partners/>
  </div>



  <div className="mt-32">
    <div className="mb-16"><center><span className="text-4xl font-bold">Pricing</span></center></div>
    <div class="grid grid-rows-1 grid-flow-col gap-4"><div><div class="mb-6"><center><span class="text-3xl font-semibold">SwiftFiber Network</span></center></div><div class="flex justify-center items-center"><img src="https://swiftportal.s3.ap-southeast-2.amazonaws.com/alburyswift.svg"/></div></div><div><div class="mb-6"><center><span class="text-3xl font-semibold">Everyone Else's Network</span></center></div><div class="flex justify-center items-center"><img src="https://swiftportal.s3.ap-southeast-2.amazonaws.com/alburyother.svg"/></div></div></div>
        </div>



<div className="mt-32 grid grid-flow-row-dense gap-8 lg:grid-cols-2 lg:grid-rows-1 sm:grid-cols-1 sm:grid-rows-2">
  <div className="flex justify-center items-center">
    <div>
      <div className="mb-6"><span className="text-4xl font-bold">SwiftFiber SoftPhone</span></div>
      <div className="mb-8"><span className="text-lg font-medium">
        This innovative platform provides a user-friendly interface that allows<br/> you to easily access and manage your phone service.
      </span></div>
      <div className="p-4 bg-green-400/20 border-2 border-green-400 rounded-md mb-4">
        <div><span className="font-bold text-lg">Make & take calls</span></div>
        <div><span className="font-medium text-md">Use the SwiftFiber SoftPhone to make, take and monitor calls</span></div>
      </div>
      <div className="p-4 bg-purple-400/20 border-2 border-purple-400 rounded-md">
        <div><span className="font-bold text-lg">Manage Users</span></div>
        <div><span className="font-medium text-md">Create & Delete users with ease</span></div>
      </div>
    </div>
  </div>
  <div><center><img className="w-12/12 rounded-2xl shadow-lg" src="https://i.imgur.com/C9SyLAS.png" /></center></div>
</div>
<Network/>


<div className="mt-32">
<div className="mb-16"><center><span className="text-4xl font-bold">Contact us</span></center></div>
<Contact/>
</div>
<div className="mt-32">
  <Footer/>
</div>
</div>




</>
  );
}

export default Albury;
