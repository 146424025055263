import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, MinusIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import ContactForm from '../components/contactForm'
import Footer from '../components/footer'
import Navbar from '../components/nav'
import Partners from '../components/partners'
import Contact from '../components/contact'
import Network from '../components/network'
import logo from '../logo.svg';
import '../App.css';

const tiers = [
  {
    name: '1RU',
    id: 'ru1',
    href: 'javascript:void(Tawk_API.toggle())',
    priceMonthly: '$80',
    description: '4 Rack Units',
    mostPopular: false,
  },
  {
    name: '2RU',
    id: 'ru2',
    href: 'javascript:void(Tawk_API.toggle())',
    priceMonthly: '$120',
    description: '2 Rack Units',
    mostPopular: true,
  },
  {
    name: '10RU',
    id: 'ru10',
    href: 'javascript:void(Tawk_API.toggle())',
    priceMonthly: '$770',
    description: '10 Rack Units',
    mostPopular: false,
  },
  {
    name: 'Half Rack',
    id: 'ru24',
    href: 'javascript:void(Tawk_API.toggle())',
    priceMonthly: '$1240',
    description: '24 Rack Units',
    mostPopular: false,
  },
  {
    name: 'Full Rack',
    id: 'ru45',
    href: 'javascript:void(Tawk_API.toggle())',
    priceMonthly: '$1830',
    description: '45 Rack Units',
    mostPopular: false,
  },
]
const sections = [
  {
    name: 'Included',
    features: [
      { name: 'Data', tiers: { ru1: '1TB', ru2: '2TB', ru10: '5TB', ru24: '5TB', ru45: '10TB' } },
      { name: 'Power', tiers: { ru1: '120W', ru2: '240W', ru10: '1.2kW', ru24: '2.4kW', ru45: '3.6kW' } },
      { name: 'PDU Ports', tiers: { ru1: '1A + 1B', ru2: '2A + 2B', ru10: '10A + 10B', ru24: '10A + 10B', ru45: '20A + 20B' } },
      { name: 'Data Ports', tiers: { ru1: '1', ru2: '2', ru10: '5', ru24: '5', ru45: '5' } },
      { name: 'IPs', tiers: { ru1: '/30', ru2: '/30', ru10: '/29', ru24: '/28', ru45: '/28' } },
      { name: 'Swipe Card', tiers: { ru10: true, ru24: true, ru45: true } },
    ],
  }
]




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function Colocation() {

  const [contactmodalopen, setcontactmodalopen] = useState(false)

  const contactmodalcancelButtonRef = useRef(null)

  useEffect(() => {
    document.title = "SwiftFiber - Australian Rack Space & Colocation"
  }, []);

  return (
<>

<Transition.Root show={contactmodalopen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={contactmodalcancelButtonRef} onClose={setcontactmodalopen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h3" className="text-center text-lg font-medium leading-6 text-gray-900">
                      Contact Form
                    </Dialog.Title>
                    <div className="mt-2">
                      <ContactForm/>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setcontactmodalopen(false)}
                    ref={contactmodalcancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    <Navbar/>
<div class="container mx-auto">


      {/* <div className='bgoverlay'></div> */}




  <div className="mt-20">
    <div className="bgoverlay rounded-3xl lg:grid lg:grid-cols-2 lg:gap-4 px-16 py-16">
      <div className="flex justify-center items-center">
        <div>
          <div><span className="text-6xl font-bold">High Performance Virtual Servers</span></div>
          <div className="mt-6"><span className="text-xl font-medium">Robust high performance virtual servers ready for any task.</span></div>
          <div>
            <a href="https://cloud.swiftfiber.com.au">
            <button
              type="button"
              className="mt-16 inline-flex items-center rounded-3xl border border-transparent bg-indigo-600 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Get Started
            </button>
            </a>
            <button
              type="button"
              onClick={() => setcontactmodalopen(true)}
              className="mt-16 ml-8 inline-flex items-center rounded-3xl border border-slate-900 px-8 py-2 text-base font-medium text-slate-900 hover:text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Get in contact
            </button>
          </div>
        </div>
      </div>
      <div className="justify-center items-center hidden lg:flex">
        <div>
          <img class="w-[600px] rounded-2xl" src="https://www.nextdc.com/themes/nextdc/assets/imgs/M1-MBE.jpg"/>
        </div>
      </div>
    </div>
<Partners/>
  </div>



  <div className="mt-32">
    <div className="mb-16"><center><span className="text-4xl font-bold">Pricing</span></center></div>
    <div className="bg-white">
      <div className="">


        {/* xs to lg */}
        <div className="mx-auto max-w-md space-y-8 lg:hidden">
          {tiers.map((tier) => (
            <section
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200' : '',
                'p-8'
              )}
            >
              <h3 id={tier.id} className="text-sm font-semibold leading-6 text-gray-900">
                {tier.name}
              </h3>
              <p className="mt-2 flex items-baseline gap-x-1 text-gray-900">
                <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                <span className="text-sm font-semibold">/month</span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                    : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                  'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                )}
              >
                Buy plan
              </a>
              <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-900">
                {sections.map((section) => (
                  <li key={section.name}>
                    <ul role="list" className="space-y-4">
                      {section.features.map((feature) =>
                        feature.tiers[tier.name] ? (
                          <li key={feature.name} className="flex gap-x-3">
                            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                              {feature.name}{' '}
                              {typeof feature.tiers[tier.name] === 'string' ? (
                                <span className="text-sm leading-6 text-gray-500">({feature.tiers[tier.name]})</span>
                              ) : null}
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="isolate mt-20 hidden lg:block">
          <div className="relative -mx-8">
            <div className="absolute inset-y-0 inset-x-4 -z-10 flex">
              <div
                className="flex w-1/6 px-4"
                aria-hidden="true"
                style={{ marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 0.33) * 25}%` }}
              >
                <div className="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
              </div>
            </div>
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col className="w-1/6" />
                <col className="w-1/6" />
                <col className="w-1/6" />
                <col className="w-1/6" />
                <col className="w-1/6" />
                <col className="w-1/6" />
              </colgroup>
              <thead>
                <tr>
                  <td />
                  {tiers.map((tier) => (
                    <th key={tier.id} scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
                      <div className="text-sm font-semibold leading-7 text-gray-900">{tier.name}</div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span className="sr-only">Price</span>
                  </th>
                  {tiers.map((tier) => (
                    <td key={tier.id} className="px-6 pt-2 xl:px-8">
                      <div className="flex items-baseline gap-x-1 text-gray-900">
                        <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                        <span className="text-sm font-semibold leading-6">/month</span>
                      </div>
                      <a
                        href={tier.href}
                        className={classNames(
                          tier.mostPopular
                            ? 'bg-indigo-600 text-white hover:bg-indigo-500'
                            : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                          'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                        )}
                      >
                        Talk to us
                      </a>
                    </td>
                  ))}
                </tr>
                {sections.map((section, sectionIdx) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        scope="colgroup"
                        colSpan={4}
                        className={classNames(
                          sectionIdx === 0 ? 'pt-8' : 'pt-16',
                          'pb-4 text-sm font-semibold leading-6 text-gray-900'
                        )}
                      >
                        {section.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.name}>
                        <th scope="row" className="py-4 text-sm font-normal leading-6 text-gray-900">
                          {feature.name}
                          <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                        </th>
                        {tiers.map((tier) => (
                          <td key={tier.id} className="py-4 px-6 xl:px-8">
                            {typeof feature.tiers[tier.id] === 'string' ? (
                              <div className="text-center text-sm leading-6 text-gray-500">
                                {feature.tiers[tier.id]}
                              </div>
                            ) : (
                              <>
                                {feature.tiers[tier.id] === true ? (
                                  <CheckIcon className="mx-auto h-5 w-5 text-indigo-600" aria-hidden="true" />
                                ) : (
                                  <MinusIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                                )}

                                <span className="sr-only">
                                  {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="rounded-md bg-blue-50 p-4 mt-8">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">Can't find something to suit your needs?</p>
          <p className="mt-3 text-sm md:mt-0 md:ml-6">
            <a href="javascript:void(Tawk_API.toggle())" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
              Chat now
              <span aria-hidden="true"> &rarr;</span>
            </a>
          </p>
        </div>
      </div>
    </div>
    </div>
        </div>



<div className="mt-32 grid grid-flow-row-dense gap-8 lg:grid-cols-2 lg:grid-rows-1 sm:grid-cols-1 sm:grid-rows-2">
  <div className="flex justify-center items-center">
    <div>
      <div className="mb-6"><span className="text-4xl font-bold">SwiftFiber Portal</span></div>
      <div className="mb-8"><span className="text-lg font-medium">
        This innovative platform provides a user-friendly interface that allows<br/> you to easily access and manage your Virtual Servers.
      </span></div>
      <div className="p-4 bg-green-400/20 border-2 border-green-400 rounded-md mb-4">
        <div><span className="font-bold text-lg">Manage your Virtual Server</span></div>
        <div><span className="font-medium text-md">Use the SwiftFiber portal to monitor your usage, connect, restart and more</span></div>
      </div>
      <div className="p-4 bg-purple-400/20 border-2 border-purple-400 rounded-md">
        <div><span className="font-bold text-lg">Order Services</span></div>
        <div><span className="font-medium text-md">Spin up new services in a matter of seconds and have them automatically provisioned</span></div>
      </div>
    </div>
  </div>
  <div><center><img className="w-12/12 rounded-2xl shadow-lg" src="https://i.imgur.com/fpRcQlQ.png" /></center></div>
</div>
<Network/>


<div className="mt-32">
<div className="mb-16"><center><span className="text-4xl font-bold">Contact us</span></center></div>
<Contact/>
</div>
<div className="mt-32">
  <Footer/>
</div>
</div>




</>
  );
}

export default Colocation;
