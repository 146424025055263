import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'

import ContactForm from '../components/contactForm'
import Footer from '../components/footer'
import Navbar from '../components/nav'
import Partners from '../components/partners'
import Contact from '../components/contact'
import Network from '../components/network'
import axios from 'axios';
import logo from '../logo.svg';
import '../App.css';






function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function DedicatedServers() {

  const [contactmodalopen, setcontactmodalopen] = useState(false)

  const contactmodalcancelButtonRef = useRef(null)
  const [plans, setPlans] = useState([])

useEffect(() => {
  document.title = "SwiftFiber - Australian Dedicated Servers"
    // ref.current.continuousStart()
axios.get("https://api.cloud.swiftfiber.com.au/v1/deploy?service=dedicatedserver2", {
})
.then((response) => {
  setPlans(response.data)
  // ref.current.complete()

});
}, []);

  return (
<>

<Transition.Root show={contactmodalopen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={contactmodalcancelButtonRef} onClose={setcontactmodalopen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h3" className="text-center text-lg font-medium leading-6 text-gray-900">
                      Contact Form
                    </Dialog.Title>
                    <div className="mt-2">
                      <ContactForm/>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setcontactmodalopen(false)}
                    ref={contactmodalcancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    <Navbar/>
<div class="container mx-auto">


      {/* <div className='bgoverlay'></div> */}




  <div className="mt-20">
    <div className="bgoverlay rounded-3xl lg:grid lg:grid-cols-2 lg:gap-4 px-16 py-16">
      <div className="flex justify-center items-center">
        <div>
          <div><span className="text-6xl font-bold">High Performance Dedicated Servers</span></div>
          <div className="mt-6"><span className="text-xl font-medium">Robust high performance Dedicated Servers ready for any task.</span></div>
          <div>
            <a href="https://cloud.swiftfiber.com.au">
            <button
              type="button"
              className="mt-16 inline-flex items-center rounded-3xl border border-transparent bg-indigo-600 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Get Started
            </button>
            </a>
            <button
              type="button"
              onClick={() => setcontactmodalopen(true)}
              className="mt-16 ml-8 inline-flex items-center rounded-3xl border border-slate-900 px-8 py-2 text-base font-medium text-slate-900 hover:text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Get in contact
            </button>
          </div>
        </div>
      </div>
      <div className="justify-center items-center hidden lg:flex">
        <div>
          <img class="w-[400px] rounded-2xl" src="https://i.imgur.com/uwBVRsm.jpg"/>
        </div>
      </div>
    </div>
<Partners/>
  </div>



  <div className="mt-32">
    <div className="mb-16"><center><span className="text-4xl font-bold">Pricing</span></center></div>











                {plans?.map((location) => (
                  <>
                    {location?.servers?.map((server, serverIdx) => (
<div className="border px-4 grid grid-cols-8 mb-2" key={server?.name}>
  <div className="col-span-7 py-3">
    <div className="flex items-center justify-between border-b pb-2 pr-4">
      <div className="col-span-5">
        <span className="font-semibold text-xl">{server?.name}</span>
        <span className="ml-2 inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">NEW</span>
      </div>
      <div>
        <img className="w-12" src={`https://swiftportal.s3.ap-southeast-2.amazonaws.com/company-logos/${server?.brand}.svg`} />
      </div>
    </div>
    <div className="flex items-center justify-between flex-wrap py-1 pr-4">
      <div className="flex-1">
        <div><span className="text-sm text-slate-400">CPU</span></div>
        <div><span className="text-sm">{server?.cpus}</span></div>
      </div>
      <div className="flex-1">
        <div><span className="text-sm text-slate-400">CORES</span></div>
        <div><span className="text-sm">{server?.cpustats}</span></div>
      </div>
      <div className="flex-1">
        <div><span className="text-sm text-slate-400">RAM</span></div>
        <div><span className="text-sm">{server?.ram}</span></div>
      </div>
      <div className="flex-1">
        <div><span className="text-sm text-slate-400">STORAGE</span></div>
        <div><span className="text-sm">{server?.disk}</span></div>
      </div>
      <div className="flex-1">
        <div><span className="text-sm text-slate-400">DATA</span></div>
        <div><span className="text-sm">{server?.bw}</span></div>
      </div>
      <div className="flex-1">
        <div><span className="text-sm text-slate-400">LOCATION</span></div>
        <div><span className="text-sm">{location?.name}</span></div>
      </div>
    </div>
  </div>
  <div className="border-l py-3 px-4 col-span-1">
    <div className="flex items-center justify-between">
      <div><span className="text-sm text-slate-500">PRICE</span></div>
      <div><span className="text-2xl text-green-500">${server?.price}</span></div>
    </div>
      <center>
        <a href="https://cloud.swiftfiber.com.au/deploy/dedicatedserver">
          <button
            type="button"
            className="mt-5 rounded-md bg-indigo-600 px-16 py-1 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Order
          </button>
        </a>
      </center>
  </div>
</div>
                    ))}
                  </>
                ))}


        </div>



<div className="mt-32 grid grid-flow-row-dense gap-8 lg:grid-cols-2 lg:grid-rows-1 sm:grid-cols-1 sm:grid-rows-2">
  <div className="flex justify-center items-center">
    <div>
      <div className="mb-6"><span className="text-4xl font-bold">SwiftFiber Portal</span></div>
      <div className="mb-8"><span className="text-lg font-medium">
        This innovative platform provides a user-friendly interface that allows<br/> you to easily access and manage your Dedicated Servers.
      </span></div>
      <div className="p-4 bg-green-400/20 border-2 border-green-400 rounded-md mb-4">
        <div><span className="font-bold text-lg">Manage your Dedicated Servers</span></div>
        <div><span className="font-medium text-md">Use the SwiftFiber portal to monitor your usage, connect, restart and more</span></div>
      </div>
      <div className="p-4 bg-purple-400/20 border-2 border-purple-400 rounded-md">
        <div><span className="font-bold text-lg">Order Services</span></div>
        <div><span className="font-medium text-md">Spin up new services in a matter of seconds and have them automatically provisioned</span></div>
      </div>
    </div>
  </div>
  <div><center><img className="w-12/12 rounded-2xl shadow-lg" src="https://i.imgur.com/fpRcQlQ.png" /></center></div>
</div>
<Network/>


<div className="mt-32">
<div className="mb-16"><center><span className="text-4xl font-bold">Contact us</span></center></div>
<Contact/>
</div>
<div className="mt-32">
  <Footer/>
</div>
</div>




</>
  );
}

export default DedicatedServers;
