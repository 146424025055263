import ReactDOM from "react-dom/client";
import { Fragment, useRef, useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import VirtualServers from "./pages/VirtualServers";
import DedicatedServers from "./pages/DedicatedServers";
import NBN from "./pages/NBN";
import Colocation from "./pages/Colocation";
import Voip from "./pages/Voip";
import Albury from "./pages/Albury";
import Status from "./components/status";
import axios from 'axios';
export default function App() {


  return (
    <>
<Status/>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/voip" element={<Voip />} />
        <Route path="/location/albury" element={<Albury />} />
        <Route path="/virtualservers" element={<VirtualServers />} />
        <Route path="/dedicatedservers" element={<DedicatedServers />} />
        <Route path="/colocation" element={<Colocation />} />
        <Route path="/nbn" element={<NBN />} />
      </Routes>
    </BrowserRouter>



    </>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);