import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import ContactForm from '../components/contactForm'
import Footer from '../components/footer'
import Navbar from '../components/nav'
import Partners from '../components/partners'
import Contact from '../components/contact'
import Network from '../components/network'

import logo from '../logo.svg';
import '../App.css';

const vpsplans = [
  { name: 'gp.xsmall', cpu: '1 vCore', memory: '2GB', storage: '25GB SSD', bandwidth: '500GB', price: 6 },
  { name: 'gp.small', cpu: '1 vCore', memory: '4GB', storage: '55GB SSD', bandwidth: '1TB', price: 10 },
  { name: 'gp.medium', cpu: '2 vCore', memory: '8GB', storage: '80GB SSD', bandwidth: '1TB', price: 20 },
  { name: 'gp.large', cpu: '4 vCore', memory: '8GB', storage: '120GB SSD', bandwidth: '3TB', price: 40 },
  { name: 'gp.xlarge', cpu: '6 vCore', memory: '16GB', storage: '200GB SSD', bandwidth: '4TB', price: 60 },
  { name: 'gp.xxlarge', cpu: '8 vCore', memory: '16GB', storage: '300GB SSD', bandwidth: '5TB', price: 80 },
  // More people...
]




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function VirtualServers() {

  const [contactmodalopen, setcontactmodalopen] = useState(false)

  const contactmodalcancelButtonRef = useRef(null)

  useEffect(() => {
    document.title = "SwiftFiber - Australian Virtual Servers"
  }, []);

  return (
<>

<Transition.Root show={contactmodalopen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={contactmodalcancelButtonRef} onClose={setcontactmodalopen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h3" className="text-center text-lg font-medium leading-6 text-gray-900">
                      Contact Form
                    </Dialog.Title>
                    <div className="mt-2">
                      <ContactForm/>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setcontactmodalopen(false)}
                    ref={contactmodalcancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    <Navbar/>
<div class="container mx-auto">


      {/* <div className='bgoverlay'></div> */}




  <div className="mt-20">
    <div className="bgoverlay rounded-3xl lg:grid lg:grid-cols-2 lg:gap-4 px-16 py-16">
      <div className="flex justify-center items-center">
        <div>
          <div><span className="text-6xl font-bold">High Performance Virtual Servers</span></div>
          <div className="mt-6"><span className="text-xl font-medium">Robust high performance virtual servers ready for any task.</span></div>
          <div>
            <a href="https://cloud.swiftfiber.com.au">
            <button
              type="button"
              className="mt-16 inline-flex items-center rounded-3xl border border-transparent bg-indigo-600 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Get Started
            </button>
            </a>
            <button
              type="button"
              onClick={() => setcontactmodalopen(true)}
              className="mt-16 ml-8 inline-flex items-center rounded-3xl border border-slate-900 px-8 py-2 text-base font-medium text-slate-900 hover:text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Get in contact
            </button>
          </div>
        </div>
      </div>
      <div className="justify-center items-center hidden lg:flex">
        <div>
          <img class="w-[600px] rounded-2xl" src="https://cdn.baxtel.com/data-center/nextdc-melbourne-m1/photos/NEXTDC-Melbourne-M1-Exterior-of-M1.jpg"/>
        </div>
      </div>
    </div>
<Partners/>
  </div>



  <div className="mt-32">
    <div className="mb-16"><center><span className="text-4xl font-bold">Pricing</span></center></div>
    <div className="px-6 lg:px-8 shadow-xl">
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 lg:pl-8">
                  Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  CPU
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Memory
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Storage
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Bandwidth
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Price
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-6 lg:pr-8">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {vpsplans.map((person) => (
                  <tr key={person.name}>
                    <td className="whitespace-nowrap py-6 pl-6 pr-3 text-sm font-medium text-gray-900 lg:pl-8">
                      {person.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.cpu}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.memory}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.storage}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.bandwidth}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">${person.price}/m</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium lg:pr-8">
                      <a href="https://cloud.swiftfiber.com.au">
                      <button
                        type="button"
                        className="inline-flex items-center rounded border border-transparent bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Deploy
                      </button>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
        </div>



<div className="mt-32 grid grid-flow-row-dense gap-8 lg:grid-cols-2 lg:grid-rows-1 sm:grid-cols-1 sm:grid-rows-2">
  <div className="flex justify-center items-center">
    <div>
      <div className="mb-6"><span className="text-4xl font-bold">SwiftFiber Portal</span></div>
      <div className="mb-8"><span className="text-lg font-medium">
        This innovative platform provides a user-friendly interface that allows<br/> you to easily access and manage your Virtual Servers.
      </span></div>
      <div className="p-4 bg-green-400/20 border-2 border-green-400 rounded-md mb-4">
        <div><span className="font-bold text-lg">Manage your Virtual Server</span></div>
        <div><span className="font-medium text-md">Use the SwiftFiber portal to monitor your usage, connect, restart and more</span></div>
      </div>
      <div className="p-4 bg-purple-400/20 border-2 border-purple-400 rounded-md">
        <div><span className="font-bold text-lg">Order Services</span></div>
        <div><span className="font-medium text-md">Spin up new services in a matter of seconds and have them automatically provisioned</span></div>
      </div>
    </div>
  </div>
  <div><center><img className="w-12/12 rounded-2xl shadow-lg" src="https://i.imgur.com/fpRcQlQ.png" /></center></div>
</div>
<Network/>


<div className="mt-32">
<div className="mb-16"><center><span className="text-4xl font-bold">Contact us</span></center></div>
<Contact/>
</div>
<div className="mt-32">
  <Footer/>
</div>
</div>




</>
  );
}

export default VirtualServers;
