import React, { useState } from 'react';


function Partners() {

return (
  <div className="grid-cols-3 grid-rows-1 lg:gap-4 py-8 hidden sm:grid">
  {/* <div className="col-span-2"><span className="text-slate-400">Don't just take our word for it.<br/> As they say, the proof is in the pudding.</span></div> */}
  <div><center><img class="h-9" src="https://i.imgur.com/autdsBD.png" alt="Mad Marketing"/></center></div>
  <div><center><img class="h-9" src="https://i.imgur.com/cqcVg4N.png" alt="Fluence Energy"/></center></div>
  <div><center><img class="h-9" src="https://i.imgur.com/VAdSguB.png" alt="Engie"/></center></div>
</div>
);
}

export default Partners;