import React, { useState } from 'react';
import { useForm } from '@formcarry/react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'


function ContactForm(props) {
 // Call the "useForm" hook in your function component
 const {state, submit} = useForm({
  id: 'PFbJ_v85vhD'
});

// Success message
if (state.submitted) {
  return    <div className="border-l-4 border-green-400 bg-green-50 p-4">
  <div className="flex">
    <div className="flex-shrink-0">
      <ExclamationTriangleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
    </div>
    <div className="ml-3">
      <p className="text-sm text-green-700">
        Contact Submitted{' '}
<br/>
        Thank's for your submission. Someone from SwiftFiber will contact you.

      </p>
    </div>
  </div>
</div>;
}

return (
  <form onSubmit={submit}>

    <div>
      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
        Email
      </label>
      <div className="mt-1">
        <input
          type="email"
          name="email"
          id="email"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="you@example.com"
          required
        />
      </div>
    </div>

    <div className="mt-6">
      <label htmlFor="message" className="block text-sm font-medium text-gray-700">
        Message
      </label>
      <div className="mt-1">
        <textarea
          rows={4}
          id="message"
          name="message"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          defaultValue={''}
          required
        />
      </div>
    </div>
<div className="flex justify-end items-center">
    <button
        type="submit"
        className="mt-6 inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
      >
        Send
      </button>
</div>
  </form>
);
}

export default ContactForm;