import { Fragment, useRef, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/20/solid'
import ContactForm from '../components/contactForm'
import Footer from '../components/footer'
import Navbar from '../components/nav'
import Partners from '../components/partners'
import Contact from '../components/contact'
import Network from '../components/network'

import logo from '../logo.svg';
import '../App.css';

const tiers = [
  {
    
    "name":"NBN 25\/5",
    "speed":"25\/5",
    "price":69,
    "setup":"0",
    "greatFor":[
      "Standard-definition (SD) streaming", "Downloading files", "Working from home" 
    ]
 },
 {
    "name":"NBN 50\/20",
    "speed":"50\/20",
    "price":79,
    "setup":"0",
    "greatFor":[
      "High-definition (HD) streaming", "Downloading files", "Online gaming", "Working from home" 
    ]
 },
 {
    "name":"NBN 100\/40",
    "speed":"100\/40",
    "price":109,
    "setup":"0",
    "greatFor":[
      "Streaming movies and shows", "Downloading large files", "Online gaming", "Working from home", "Multiple devices"
    ]
 },
 {
    "name":"NBN 250\/25",
    "speed":"250\/250",
    "price":125,
    "setup":"0",
    "greatFor":[
      "Streaming 4K/8K movies and shows", "Downloading and uploading massive files", "Online gaming", "loads of devices"
    ]
 },
 {
    "name":"NBN 1000\/50",
    "speed":"1000\/50",
    "price":139,
    "setup":"0",
    "greatFor":[
      "Streaming 4K/8K movies and shows", "Downloading and uploading massive files", "Online gaming", "loads of data hungry devices"
    ]
 }
 ]




function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


function NBN() {

  const [contactmodalopen, setcontactmodalopen] = useState(false)

  const contactmodalcancelButtonRef = useRef(null)

  useEffect(() => {
    document.title = "SwiftFiber - NBN"
  }, []);

  return (
<>

<Transition.Root show={contactmodalopen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={contactmodalcancelButtonRef} onClose={setcontactmodalopen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title as="h3" className="text-center text-lg font-medium leading-6 text-gray-900">
                      Contact Form
                    </Dialog.Title>
                    <div className="mt-2">
                      <ContactForm/>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    onClick={() => setcontactmodalopen(false)}
                    ref={contactmodalcancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    <Navbar/>
<div class="container mx-auto">


      {/* <div className='bgoverlay'></div> */}




  <div className="mt-20">
    <div className="bgoverlay rounded-3xl lg:grid lg:grid-cols-2 lg:gap-4 px-16 py-16">
      <div className="flex justify-center items-center">
        <div>
          <div><span className="text-6xl font-bold">High Speed & Reliable NBN</span></div>
          <div className="mt-6"><span className="text-xl font-medium">Super fast NBN ready for anything in todays world.</span></div>
          <div>
            <a href="https://cloud.swiftfiber.com.au">
            <button
              type="button"
              className="mt-16 inline-flex items-center rounded-3xl border border-transparent bg-indigo-600 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Get Started
            </button>
            </a>
            <button
              type="button"
              onClick={() => setcontactmodalopen(true)}
              className="mt-16 ml-8 inline-flex items-center rounded-3xl border border-slate-900 px-8 py-2 text-base font-medium text-slate-900 hover:text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Get in contact
            </button>
          </div>
        </div>
      </div>
      <div className="justify-center items-center hidden lg:flex">
        <div>
          <img class="w-[600px] rounded-2xl" src="https://www.nbnco.com.au/content/dam/nbnco2/images/blog/new/what-is-fttc-poi-1043.jpg.transform/w1440/optimized/image.jpg"/>
        </div>
      </div>
    </div>
<Partners/>
  </div>



  <div className="mt-32">
    <div className="mb-16"><center><span className="text-4xl font-bold">Pricing</span></center></div>
    <div className="px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
        <div className="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
          {tiers.map((tier) => (
            <div key={tier.name} className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">{tier?.name}</h2>
                <p className="mt-4 text-sm text-gray-500">{tier?.speed} Mbps</p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">${tier?.price}</span>{' '}
                  <span className="text-base font-medium text-gray-500">/mo</span>
                </p>
                <a
                  href="https://cloud.swiftfiber.com.au/deploy/nbn"
                  className="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900"
                >
                  Buy {tier?.name}
                </a>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">Great For</h3>
                <ul role="list" className="mt-6 space-y-4">
                  {tier?.greatFor?.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        </div>
      </div>
    </div>
        </div>



<div className="mt-32 grid grid-flow-row-dense gap-8 lg:grid-cols-2 lg:grid-rows-1 sm:grid-cols-1 sm:grid-rows-2">
  <div className="flex justify-center items-center">
    <div>
      <div className="mb-6"><span className="text-4xl font-bold">SwiftFiber Portal</span></div>
      <div className="mb-8"><span className="text-lg font-medium">
        This innovative platform provides a user-friendly interface that allows<br/> you to easily access and manage your NBN service.
      </span></div>
      <div className="p-4 bg-green-400/20 border-2 border-green-400 rounded-md mb-4">
        <div><span className="font-bold text-lg">Manage your NBN connection</span></div>
        <div><span className="font-medium text-md">Use the SwiftFiber portal to monitor your usage, run tests, modify service and more</span></div>
      </div>
      <div className="p-4 bg-purple-400/20 border-2 border-purple-400 rounded-md">
        <div><span className="font-bold text-lg">Order Services</span></div>
        <div><span className="font-medium text-md">Spin up new services in a matter of seconds and have them automatically provisioned</span></div>
      </div>
    </div>
  </div>
  <div><center><img className="w-12/12 rounded-2xl shadow-lg" src="https://i.imgur.com/AruveKU.png" /></center></div>
</div>
<Network/>


<div className="mt-32">
<div className="mb-16"><center><span className="text-4xl font-bold">Contact us</span></center></div>
<Contact/>
</div>
<div className="mt-32">
  <Footer/>
</div>
</div>




</>
  );
}

export default NBN;
