import { Fragment, useRef, useState, useEffect } from 'react'
import axios from 'axios';
import React  from 'react';
import Moment from 'react-moment';

function Status() {
  const [incidents, setIncidents] = useState([])
  useEffect(() => {
      // ref.current.continuousStart()
  axios.get("https://status.swiftfiber.com.au/api/v2/incidents/unresolved.json", {
  })
  .then((response) => {
    setIncidents(response.data.incidents)
    // ref.current.complete()
  
  });
  }, []);
return (
<>
{incidents?.map((status) => (
  <>
  {status.impact != "none" &&
    <div className="flex items-center justify-between gap-x-6 bg-gray-900 py-2.5 px-6 sm:pr-3.5 lg:pl-8">
    <p className="text-sm leading-6 text-white">
      <a href={status.shortlink} target="_blank">
      <strong className="font-semibold">
        {' '}{status.status.replace("_"," ")}
        {/* {status?.components?.map((component) => (
          <>
           {' '} - {component.description}
          </>
        ))} */}
        </strong>
      <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
        <circle cx={1} cy={1} r={1} />
      </svg>
      {status.incident_updates[0].body}
      {/* &nbsp;<span aria-hidden="true">&rarr;</span> */}
    </a>
    </p>
    <span className="-m-3 flex-none p-3 focus-visible:outline-offset-[-4px] text-white text-sm">
    <Moment format="Do MMM YYYY [@] LT">{status.incident_updates[0].updated_at}</Moment>
    </span>
  </div>
}
</>
    ))}
</>
);
}

export default Status;