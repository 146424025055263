import React, { useState } from 'react';


function Network() {

return (
<div className="mt-32 grid grid-flow-row-dense gap-8 lg:grid-cols-2 lg:grid-rows-1 sm:grid-cols-1 sm:grid-rows-2">
  <div><center><img className="w-12/12" src="https://i.imgur.com/hrC33pC.png" /></center></div>
  <div className="flex justify-center items-center">
    <div>
      <div className="mb-6"><span className="text-4xl font-bold">SwiftFiber Network</span></div>
      <div className="mb-8"><span className="text-lg font-medium">
      Our network is backed by Corero DDoS protection and cutting-edge technology,<br/> ensuring your data is always protected and available whenever you need it.
      </span></div>
      <div className="p-4 bg-blue-400/20 border-2 border-blue-400 rounded-md mb-4">
        <div><span className="font-bold text-lg">Premium Transit</span></div>
        <div><span className="font-medium text-md">SwiftFiber utilises Global Secure Layer for our upstream connection allowing superior performance and low latency</span></div>
      </div>
      <div className="p-4 bg-red-400/20 border-2 border-red-400 rounded-md">
        <div><span className="font-bold text-lg">Corero DDoS protection</span></div>
        <div><span className="font-medium text-md">SwiftFiber utilises in-line DDoS mitigation devices from Corero ensuring your services never go down</span></div>
      </div>
    </div>
  </div>
</div>
);
}

export default Network;